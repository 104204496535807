<template>
	<div class="form">
		<b-row v-if="createByOtherUser">
			<b-col>
				<div class="float-right">
					<div class="fs-14 fw-500">
						{{ FormMSG(245, 'Created by') }} {{ transport.createdByUser.name + ' ' + transport.createdByUser.firstName }} -
						{{ transport.createdAt | formatDate('DD/MM/YYYY HH:mm') }}
					</div>
				</div>
			</b-col>
		</b-row>
		<b-form-group v-if="!isMyTransport" :label="FormMSG(68, 'Crew member')">
			<v-select
				v-model="transport.userId"
				:options="users"
				:placeholder="FormMSG(75, 'Choose a user')"
				:reduce="(option) => option.id"
				label="fullName"
				:clearable="false"
				:disabled="createByOtherUser"
				@input="handleInputChooseAUser"
			/>
		</b-form-group>
		<b-form-group :label="FormMSG(69, 'Choose a date')">
			<b-form-datepicker id="transport-date" v-model="chooseDate" :locale="currentLang" :disabled="createByOtherUser" @input="handleInputChooseDate" />
		</b-form-group>
		<b-form-group :label="FormMSG(102, 'Description')">
			<b-form-textarea
				v-model="$v.transport.extraInfo.$model"
				id="comment"
				rows="3"
				:disabled="createByOtherUser"
				:state="!$v.transport.extraInfo.$error"
			/>
			<div v-if="$v.transport.extraInfo.$error === true" class="invalid-feedback">
				{{ FormMSG(578, 'Description is required') }}
			</div>
		</b-form-group>
		<b-form-group :label="FormMSG(1258, 'Production phase')">
			<b-form-select id="production-phase" v-model="transport.productionPhase" :options="productionPhaseOptions" :disabled="createByOtherUser" />
		</b-form-group>
		<b-row>
			<b-col sm="12" md="12" lg="12" xl="12">
				<vehicules-card
					:disabled="createByOtherUser"
					:edit-data="transport"
					:can-add-and-edit="!isMyTransport"
					:user-id="currentUserId"
					@change="handleCarChange"
				/>
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<transport-type-selector
					ref="transportTypSelectorTransport"
					:data-additional-detail="transport.additionalSubType"
					:data-detail="transport.subType"
					:data-type="transport.type"
					:disable-form="isDefinedVehicles"
					hide-special-vehicles
					@transportation-type-selector:change="handleTransportationTypeSelectorChange"
				/>
			</b-col>
		</b-row>
		<b-row>
			<b-col sm="12" md="5" lg="5" xl="5">
				<b-form-group v-if="[9, 10, 11, 12, 19].includes(transportTypeMapped)" :label="FormMSG(115, 'Material weight')">
					<b-input-group>
						<b-form-input
							v-model="$v.transport.materialWeight.$model"
							:disabled="createByOtherUser"
							:state="getStateValidationField('transport.materialWeight')"
						/>
						<b-input-group-append>
							<div class="pl-3 fs-20 fw-700">Kg</div>
						</b-input-group-append>
					</b-input-group>
					<div v-if="$v.transport.materialWeight.$error && getStateValidationField('transport.materialWeight') !== null" class="invalid-feedback">
						{{ FormMSG(6871, 'Please enter material weight') }}
					</div>
				</b-form-group>
			</b-col>
		</b-row>

		<div>
			<address-maker
				type="from"
				:edit-data="transport"
				:disabled="createByOtherUser"
				:show-my-address="isMyTransport"
				@change="handleChangeFromAddress"
			/>
		</div>

		<div class="mb-3 mt-3">
			<address-maker type="to" :edit-data="transport" :disabled="createByOtherUser" :show-my-address="isMyTransport" @change="handleChangeToAddress" />
		</div>

		<div v-if="!createByOtherUser">
			<b-row v-if="showGooglePlanification">
				<b-col>
					<fieldset class="scheduler-border border-groove-blue-streak pb-0 bg-color-grams-hair-color">
						<legend class="scheduler-border text-color-blue-streak">
							{{ FormMSG(145, 'Google planification') }}
						</legend>
						<b-row>
							<b-col :cols="$screen.width < 567 ? 6 : 3">
								<b-form-checkbox
									v-model="avoidHighways"
									size="lg"
									:value="true"
									:unchecked-value="false"
									:disabled="createByOtherUser || cantUseGooglePlanification"
								>
									{{ FormMSG(165, 'Avoid highways') }}
								</b-form-checkbox>
							</b-col>
							<b-col :cols="$screen.width < 567 ? 6 : 3">
								<b-form-checkbox
									v-model="avoidTolls"
									size="lg"
									:value="true"
									:unchecked-value="false"
									:disabled="createByOtherUser || cantUseGooglePlanification"
								>
									{{ FormMSG(166, 'Avoid tolls') }}
								</b-form-checkbox>
							</b-col>
						</b-row>
						<b-row class="mt-3">
							<b-col sm="12" md="5" lg="5" xl="5">
								<b-form-group :label="FormMSG(167, 'Preference')">
									<b-form-select
										v-model="transport.depArrStd"
										:options="datesOptionsMap"
										:disabled="createByOtherUser || cantUseGooglePlanification"
									/>
								</b-form-group>
							</b-col>
							<b-col sm="12" md="5" lg="5" xl="5">
								<b-form-group :label="FormMSG(169, 'Choose a time')">
									<b-input-group>
										<b-form-input
											type="time"
											v-model="chooseTime"
											:state="chooseTimeState.state"
											:disabled="createByOtherUser || cantUseGooglePlanification"
											@input="handleInputChooseTime"
										/>
										<b-input-group-append>
											<b-input-group-text>
												<component :is="getLucideIcon('Clock')" color="#06263E" :size="16" />
											</b-input-group-text>
										</b-input-group-append>
										<div v-if="chooseTimeState.state === false" class="invalid-feedback">
											{{ chooseTimeState.message }}
										</div>
									</b-input-group>
								</b-form-group>
							</b-col>
							<b-col sm="12" md="2" lg="2" xl="2" :class="$screen.width > 567 ? 'pt-28' : 'mb-3'">
								<b-button
									size="sm"
									block
									:disabled="disableComputeBtn || isDurationEstimationLoading || createByOtherUser || cantUseGooglePlanification"
									@click="plannifiedWithGoogle"
								>
									<b-spinner small v-if="isDurationEstimationLoading" label="Loading..." />
									{{ FormMSG(178, 'Compute') }}
								</b-button>
							</b-col>
						</b-row>
					</fieldset>
				</b-col>
			</b-row>
		</div>

		<b-row>
			<b-col sm="12" md="6" lg="6" xl="6">
				<b-form-group :label="FormMSG(109, 'Travel distance')">
					<b-row>
						<b-col cols="10">
							<b-form-input
								v-model="$v.transport.km.$model"
								type="number"
								:state="!$v.transport.km.$error"
								@change="handleChangeTravelDistance"
								:disabled="createByOtherUser"
							/>
							<div v-if="$v.transport.km.$error === true" class="invalid-feedback">
								{{ FormMSG(78, 'Travel distance is required') }}
							</div>
						</b-col>
						<b-col cols="2">
							<div class="fs-20 fw-700">{{ labelKm }}</div>
						</b-col>
					</b-row>
				</b-form-group>
			</b-col>
			<b-col sm="12" md="6" lg="6" xl="6">
				<b-form-group :label="FormMSG(110, 'Green impact (Kg CO2)')">
					<b-form-input v-model="greenImpact" :disabled="createByOtherUser" />
				</b-form-group>
			</b-col>
		</b-row>

		<b-row>
			<b-col sm="12" md="4" lg="4" xl="4">
				<b-form-group :label="FormMSG(111, 'Departure time')">
					<b-input-group>
						<b-form-input type="time" v-model="departureTime" :disabled="createByOtherUser" @input="handleInputDepartureTime" />
						<b-input-group-append>
							<b-input-group-text>
								<component :is="getLucideIcon('Clock')" color="#06263E" :size="16" />
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
			</b-col>
			<b-col sm="12" md="4" lg="4" xl="4">
				<b-form-group :label="FormMSG(112, 'Arrival time')">
					<b-input-group>
						<b-form-input type="time" v-model="arrivalTime" :disabled="createByOtherUser" @input="handleInputArrivalTime" />
						<b-input-group-append>
							<b-input-group-text>
								<component :is="getLucideIcon('Clock')" color="#06263E" :size="16" />
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
			</b-col>
			<b-col sm="12" md="4" lg="4" xl="4">
				<b-form-group :label="FormMSG(113, 'Duration')">
					<b-input-group>
						<b-form-input :value="durationTime" disabled />
						<b-input-group-append>
							<b-input-group-text>
								<component :is="getLucideIcon('Clock')" color="#06263E" :size="16" />
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
			</b-col>
		</b-row>

		<div class="mb-5">
			<DriverAndPassengerCard :disabled="createByOtherUser" :transport="transport" @car-change="handleCarChange" />
		</div>

		<b-row v-if="!hideActionFooter" class="footer-fixed">
			<b-col cols="6">
				<b-button block class="fs-16 fw-700 bg-color-grams-hair-color" variant="none" @click="handleClickBack">
					{{ FormMSG(117, 'Cancel') }}
				</b-button>
			</b-col>
			<b-col cols="6">
				<b-button variant="primary" block class="fs-16 fw-700" @click="handleClickSave">
					{{ FormMSG(116, 'Save') }}
				</b-button>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import { getUsers } from '@/cruds/users.crud';
import { getVehicules, getVehicule } from '@/cruds/vehicules.crud';

import AddressMaker from '@/modules/transports/components/AddressMaker';

import { updatedTransport } from '@/cruds/transports.crud';

import _ from 'lodash';
import moment from 'moment';
import { GetKmGreenImpact } from '@/cruds/carbon.crud';
import { quickSave, getTravelModeFromTransportMean, isStarted, isArrived, setDurationToDate } from '@/modules/transports/utils';
import { fetchDistance } from '~helpers';
import VehiculesCard from '@/modules/transports/components/VehiculesCard';
import DriverAndPassengerCard from '@/modules/transports/components/DriverAndPassengerCard';
import { store } from '@/store';
import { isTimeValide } from '@/utils/validators';
import { validationMixin } from 'vuelidate';
import { required, minValue, minLength, maxLength } from 'vuelidate/lib/validators';
import { getAddress } from '@/cruds/address.crud';
import TransportTypeSelector from './TransportTypeSelector.vue';
import { PRODUCTION_PHASE_MENU } from '../../../shared/constants';

const EXCEPTION_TO_COMPUTE = [0, 5, 7, 9, 18, 19, 20, 41, 42, 43];

export default {
	name: 'FormTransport',

	props: {
		editData: { type: Object, default: null },
		submitForm: { type: Boolean, default: false },
		isMyTransport: { type: Boolean, default: false },
		resetForm: { type: Boolean, default: false },
		hideActionFooter: { type: Boolean, default: true }
	},

	mixins: [languageMessages, GlobalMixin, validationMixin],

	components: {
		AddressMaker,
		VehiculesCard,
		DriverAndPassengerCard,
		TransportTypeSelector
	},

	data() {
		return {
			users: [],
			userSelected: null,

			chooseDate: moment(new Date()).format('YYYY-MM-DD'),
			chooseTime: '--:--',
			departureTime: '--:--',
			arrivalTime: '--:--',

			transport: {
				type: 0,
				subType: 0,
				additionalSubType: 0,
				productionPhase: 0
			},
			transportOriginal: {},

			avoidHighways: false,
			avoidTolls: false,

			vehiculesOptionsList: [],
			durationTime: '00:00',

			greenImpact: 0,

			currVehicleToEdit: null,
			showModalVehicle: false,
			currSearch: '',

			createByOtherUser: false,

			isDurationEstimationLoading: false,
			durationEstimation: {},

			chooseTimeState: {
				state: null,
				message: ''
			},

			datePlanifiedByGoogle: false,
			currentUserId: 0,
			isDefinedVehicles: false,
			transportTypeMapped: 0
		};
	},

	validations() {
		const CHECK = [9, 10, 11, 12, 19];
		return {
			transport: {
				km: { required, minValue: minValue(1) },
				extraInfo: { required },
				materialWeight: {
					required: CHECK.includes(this.transportTypeMapped) ? required : {},
					minValue: CHECK.includes(this.transportTypeMapped) ? minValue(1) : {}
				}
			}
		};
	},

	watch: {
		editData: {
			async handler(newVal) {
				if (!_.isNil(newVal)) {
					this.transport = _.cloneDeep(newVal);
					this.transport.vehiculeId = this.transport.vehiculeId === 0 ? null : this.transport.vehiculeId;
					this.transport.userId = this.transport.userId === 0 ? null : '' + this.transport.userId;
					this.departureTime = newVal.forCreate ? '--:--' : moment(new Date(this.transport.departureTime)).format('HH:mm');
					this.arrivalTime = newVal.forCreate ? '--:--' : moment(new Date(this.transport.arrivalTime)).format('HH:mm');
					this.initCreateByOtherUser();
					this.initChooseDate();
					this.initDepartAndArrivalTime();
					this.calculDuration();
					await this.getGreenImpact();

					this.currentUserId = parseInt(this.transport.userId);

					this.transportOriginal = _.cloneDeep(newVal);

					this.transportTypeMapped = this.mappedTransportTypeToCarbonClapValue(newVal.type, newVal.subType, newVal.additionalSubType);
				}
			},
			immediate: true
		},
		submitForm: {
			async handler(newVal) {
				if (newVal === true) {
					this.$v.$touch();

					const checkTransportIsInvalid = this.$refs['transportTypSelectorTransport'].isInvalid();

					if (!this.$v.$invalid && checkTransportIsInvalid === false) {
						await this.submit();
						this.createToastForMobile(this.FormMSG(48, 'Success'), this.FormMSG(49, 'Transport updated successfully!'));
						this.$emit('form-transport:submit-success');
					} else {
						this.$emit('form-transport:submit-failed');
						return;
					}
				}
			},
			immediate: true
		},
		resetForm: {
			async handler(newVal) {
				if (newVal === true) {
					this.resetData();
				}
			},
			immediate: true
		},
		isMyTransport: {
			handler(newVal) {
				if (newVal === true) {
					this.currentUserId = store.userID();
				}
			},
			immediate: true
		}
	},

	computed: {
		productionPhaseOptions() {
			return [{ text: this.FormMSG(6254, 'Undefined (all phases)'), value: 0 }, ...this.FormMenu(PRODUCTION_PHASE_MENU)];
		},
		labelKm() {
			return store.state.myProfile.distanceUnit;
		},
		cantUseGooglePlanification() {
			if (EXCEPTION_TO_COMPUTE.includes(this.transportTypeMapped)) {
				return true;
			}

			return false;
		},

		showGooglePlanification() {
			const dateNow = moment(new Date()).format('YYYY-MM-DD') + 'T00:00:00Z';
			const dateNowToTime = new Date(dateNow).getTime();
			const chooseDateToTime = new Date(this.chooseDate + 'T00:00:00Z').getTime();

			if (chooseDateToTime < dateNowToTime) {
				return false;
			}

			return true;
		},
		disableComputeBtn() {
			return this.transport.fromAddressId === 0 || this.transport.toAddressId === 0;
		},
		reasonMenues() {
			return this.FormMenu(10007);
		},
		transportationMeanOptions() {
			let result = this.FormMenu(905);

			if (store.getCurrentProjectConfig().carbonModel === 2) {
				result = result.filter((option) => option.value < 41);
			}

			result = result.sort(function compare(a, b) {
				if (a.text.toLowerCase() < b.text.toLowerCase()) return -1;
				if (a.text.toLowerCase() > b.text.toLowerCase()) return 1;
				return 0;
			});

			return result;
		},
		datesOptionsMap() {
			return [
				{ value: 0, text: this.FormMSG(131, 'Departure at') },
				{ value: 1, text: this.FormMSG(121, 'Arrival at') }
			];
		}
	},

	async mounted() {
		await this.getUsers();
	},

	methods: {
		async handleTransportationTypeSelectorChange({ type, detail, additionalDetail }) {
			this.transport.type = type;
			this.transport.subType = detail === null ? 0 : detail;
			this.transport.additionalSubType = additionalDetail === null ? 0 : additionalDetail;

			this.transportTypeMapped = this.mappedTransportTypeToCarbonClapValue(this.transport.type, this.transport.subType, this.transport.additionalSubType);

			if (
				this.transport.fromAddressId > 0 &&
				this.transport.toAddressId > 0 &&
				this.transportTypeMapped > 0 &&
				!EXCEPTION_TO_COMPUTE.includes(this.transportTypeMapped)
			) {
				await this.plannificationGoogle(true);
			}
		},

		handleInputChooseAUser(payload) {
			this.currentUserId = parseInt(payload);
			this.transport.vehiculeId = null;
		},
		handleInputChooseDate() {
			this.chooseTime = '--:--';
			this.departureTime = '--:--';
			this.arrivalTime = '--:--';
			this.transport.departureTime = '0001-01-01T00:00:00Z';
			this.transport.arrivalTime = '0001-01-01T00:00:00Z';
			this.durationTime = '--:--';
		},
		handleClickBack() {
			this.$router.go(-1);
		},
		async handleClickSave() {
			this.$v.$touch();

			const checkTransportIsInvalid = this.$refs['transportTypSelectorTransport'].isInvalid();

			if (!this.$v.transport.extraInfo.$error || !this.$v.transport.km.$error || checkTransportIsInvalid === false) {
				await this.submit();
				this.createToastForMobile(this.FormMSG(48, 'Success'), this.FormMSG(49, 'Transport updated successfully!'));
				this.$emit('form-transport:submit-success');
			} else {
				this.$emit('form-transport:submit-failed');
				return;
			}
		},
		async handleCarChange(vehiculeId) {
			if (_.isNil(vehiculeId)) {
				this.isDefinedVehicles = false;
			} else {
				this.isDefinedVehicles = true;
				const vehicule = await getVehicule(+vehiculeId);
				this.transport.vehiculeId = vehiculeId;
				this.transport.type = vehicule.type;
				this.transport.subType = vehicule.subType;
				this.transport.additionalSubType = vehicule.additionalSubType;
			}
		},
		handleInputChooseTime(payload) {
			if (payload !== '--:--') {
				const date = new Date();
				const dateNow = moment(date).utc().format('YYYY-MM-DD');
				const dateToTime = date.getTime();
				const payloadToTime = new Date(`${this.chooseDate} ${payload}`).getTime();

				if (payloadToTime < dateToTime) {
					this.chooseTimeState.state = false;
					this.chooseTimeState.message = this.FormMSG(157, 'The time must be greater than or equal to now');
					return false;
				} else {
					this.chooseTimeState.state = null;
					this.chooseTimeState.message = '';
					return true;
				}
			}
		},
		checkChooseTime() {
			if (this.chooseTime === '--:--') {
				this.chooseTimeState.state = false;
				this.chooseTimeState.message = this.FormMSG(124, 'Please enter a time');
				this.errorState.chooseTime = false;
				return false;
			} else {
				if (!this.handleInputChooseTime(this.chooseDate)) {
					return false;
				}
			}

			this.chooseTimeState.error = null;
			this.chooseTimeState.message = '';
			return true;
		},
		async plannifiedWithGoogle() {
			if (!this.checkChooseTime()) return;

			this.isDurationEstimationLoading = true;

			await this.plannificationGoogle();

			this.isDurationEstimationLoading = false;
		},
		async plannificationGoogle(getOnlyKmAndCoTwo = false) {
			const fromAddressId = this.transport.fromAddressId;
			const toAddressId = this.transport.toAddressId;

			if (fromAddressId === 0 || toAddressId === 0) return;

			const from = await getAddress(fromAddressId);
			const to = await getAddress(toAddressId);

			const travel = getTravelModeFromTransportMean(this.transportTypeMapped);

			const dateTimeField = this.transport.depArrStd === 0 ? 'departureTime' : 'arrivalTime';

			try {
				const distancePayload = {
					from,
					to,
					...travel,
					avoidHighways: this.avoidHighways || false,
					avoidTolls: this.avoidTolls || false,
					[dateTimeField]: this.chooseDate + 'T' + this.chooseTime + ':00Z'
				};

				const res = await fetchDistance(distancePayload);

				this.durationEstimation = {
					status: 'success',
					estimation: res
				};

				if (getOnlyKmAndCoTwo === false) {
					await this.calculDurationTime(res, dateTimeField, distancePayload);

					this.datePlanifiedByGoogle = true;
					this.initDepartAndArrivalTime();
					this.calculDuration();
				} else {
					await this.calculDurationTime(res, dateTimeField, distancePayload, true);
				}
			} catch (e) {
				this.durationEstimation = {
					status: 'error',
					message: e,
					estimation: null
				};
			}
		},
		async calculDurationTime(result, dateTimeField, distancePayload, getOnlyKmAndCoTwo = false) {
			const elem = result.rows[0].elements[0];

			if (_.isNil(elem.duration)) return;

			const durationInSeconds = elem.duration.value;
			const distanceInKm = elem.distance.value / 1000;

			const kgCoTwo = distanceInKm > 0 ? await GetKmGreenImpact(distanceInKm, this.transport.type, this.transport.subType) : 0;

			const estimatedDepartureTime =
				dateTimeField === 'arrivalTime'
					? setDurationToDate(distancePayload.arrivalTime, durationInSeconds, { unit: 'seconds', type: 'remove' })
					: moment(new Date(this.chooseDate + 'T' + this.chooseTime + ':00Z')).format();

			const estimatedArrivalTime =
				dateTimeField === 'arrivalTime'
					? moment(new Date(this.chooseDate + 'T' + this.chooseTime + ':00Z')).format()
					: setDurationToDate(distancePayload.departureTime, durationInSeconds);

			if (getOnlyKmAndCoTwo === false) {
				this.transport = {
					...this.transport,
					departureTime: estimatedDepartureTime,
					arrivalTime: estimatedArrivalTime,
					km: distanceInKm,
					kgCoTwo
				};
			} else {
				this.transport = {
					...this.transport,
					km: distanceInKm,
					kgCoTwo
				};
			}

			this.greenImpact = kgCoTwo;
		},
		initCreateByOtherUser() {
			if (this.isMyTransport) {
				if (parseInt(this.transport.userId, 10) !== parseInt(this.transport.createdBy, 10)) {
					this.createByOtherUser = true;
				} else {
					this.createByOtherUser = false;
				}
			}
		},
		initDepartAndArrivalTime() {
			if (!this.datePlanifiedByGoogle) {
				this.departureTime =
					this.transport.departureTime !== '0001-01-01T00:00:00Z' ? moment(new Date(this.transport.departureTime)).format('HH:mm') : '--:--';
				this.arrivalTime =
					this.transport.arrivalTime !== '0001-01-01T00:00:00Z' ? moment(new Date(this.transport.arrivalTime)).format('HH:mm') : '--:--';
			} else {
				this.departureTime =
					this.transport.departureTime !== '0001-01-01T00:00:00Z' ? moment(new Date(this.transport.departureTime)).utc().format('HH:mm') : '--:--';
				this.arrivalTime =
					this.transport.arrivalTime !== '0001-01-01T00:00:00Z' ? moment(new Date(this.transport.arrivalTime)).utc().format('HH:mm') : '--:--';
			}
		},
		handleInputDepartureTime(payload) {
			if (payload === '--:--') return;
			this.transport.departureTime = `${this.chooseDate}T${payload}:00Z`;
			// this.calculDuration();
		},
		handleInputArrivalTime(payload) {
			if (payload === '--:--') return;
			this.transport.arrivalTime = `${this.chooseDate}T${payload}:00Z`;
			this.calculDuration();
		},
		async handleChangeTravelDistance(payload) {
			this.transport.km = parseInt(payload);
			await this.getGreenImpact();
		},
		async handleChangeFromAddress({ addressId, key, value }) {
			const checkAddress = await this.checkIfAddressIsValid(addressId, 'from');
			if (checkAddress === false) {
				this.createToastForMobile(this.FormMSG(594, 'Error'), this.FormMSG(565, 'From address is invalid'), '', 'danger');
				return;
			}
			if (_.isNil(key)) {
				this.transport.fromAddressId = addressId;
				this.transport.fromLocationId = 0;
				this.transport.fromUserId = 0;
			} else if (key === 'fromLocationId') {
				this.transport.fromAddressId = addressId;
				this.transport.fromLocationId = value;
				this.transport.fromUserId = 0;
			} else if (key === 'fromUserId') {
				this.transport.fromAddressId = addressId;
				this.transport.fromLocationId = 0;
				this.transport.fromUserId = value;
			}

			if (
				this.transport.fromAddressId > 0 &&
				this.transport.toAddressId > 0 &&
				this.transportTypeMapped > 0 &&
				!EXCEPTION_TO_COMPUTE.includes(this.transportTypeMapped)
			) {
				await this.plannificationGoogle(true);
			}
		},
		async checkIfAddressIsValid(addressId, type = 'from') {
			const result = await getAddress(+addressId);
			if (
				result.street === '' &&
				result.number === '' &&
				result.box === '' &&
				result.zip === '' &&
				result.state === '' &&
				result.city === '' &&
				result.country === '' &&
				result.other === '' &&
				result.lat === 0 &&
				result.lng === 0
			) {
				if (type === 'from') {
					this.transport.fromAddressId = 0;
					this.transport.fromLocationId = 0;
					this.transport.fromUserId = 0;
				} else if (type === 'to') {
					this.transport.toAddressId = 0;
					this.transport.toLocationId = 0;
					this.transport.toUserId = 0;
				}
				return false;
			}

			return true;
		},
		async handleChangeToAddress({ addressId, key, value }) {
			const checkAddress = await this.checkIfAddressIsValid(addressId, 'to');
			if (checkAddress === false) {
				this.createToastForMobile(this.FormMSG(594, 'Error'), this.FormMSG(568, 'To address is invalid'), '', 'danger');
				return;
			}
			if (_.isNil(key)) {
				this.transport.toAddressId = addressId;
				this.transport.toLocationId = 0;
				this.transport.toUserId = 0;
			} else if (key === 'toLocationId') {
				this.transport.toAddressId = addressId;
				this.transport.toLocationId = value;
				this.transport.toUserId = 0;
			} else if (key === 'toUserId') {
				this.transport.toAddressId = addressId;
				this.transport.toLocationId = 0;
				this.transport.toUserId = value;
			}

			if (
				this.transport.fromAddressId > 0 &&
				this.transport.toAddressId > 0 &&
				this.transportTypeMapped > 0 &&
				!EXCEPTION_TO_COMPUTE.includes(this.transportTypeMapped)
			) {
				await this.plannificationGoogle(true);
			}
		},
		async getGreenImpact() {
			this.greenImpact = await GetKmGreenImpact(this.transport.km, this.transport.type, this.transport.subType);
		},
		calculDuration() {
			if (this.transport.departureTime === '0001-01-01T00:00:00Z') return;
			if (this.transport.arrivalTime === '0001-01-01T00:00:00Z') return;
			const date1 = new Date(this.transport.departureTime).getTime();
			const date2 = new Date(this.transport.arrivalTime).getTime();

			let distance = Math.abs(date1 - date2);
			let hours = Math.floor(distance / 3600000);
			distance -= hours * 3600000;
			let minutes = Math.floor(distance / 60000);

			if (hours < 9) {
				hours = '0' + hours;
			}

			if (minutes < 9) {
				minutes = '0' + minutes;
			}

			const hoursDiff = `${hours}:${('0' + minutes).slice(-2)}`;

			this.durationTime = hoursDiff;
		},
		async initVehiculesOptionsList() {
			this.vehiculesOptionsList = await getVehicules();
		},
		initChooseDate() {
			this.chooseDate = moment(new Date(this.transport.departureDate)).format('YYYY-MM-DD');
		},
		async getUsers() {
			this.users = await getUsers({ myProject: true });
		},
		prepareDataToSend() {
			let result = {
				userId: parseInt(this.transport.userId),
				extraInfo: this.transport.extraInfo,
				productionPhase: this.transport.productionPhase,
				type: this.transport.type,
				subType: this.transport.subType,
				additionalSubType: this.transport.additionalSubType,
				vehiculeId: parseInt(this.transport.vehiculeId),
				fromAddressId: this.transport.fromAddressId,
				fromLocationId: this.transport.fromLocationId,
				fromUserId: this.transport.fromUserId,
				toAddressId: this.transport.toAddressId,
				toLocationId: this.transport.toLocationId,
				toUserId: parseInt(this.transport.toUserId, 10),
				depArrStd: this.transport.depArrStd,
				km: this.transport.km,
				departureDate: this.chooseDate + 'T00:00:00Z',
				departureTime:
					this.transport.departureTime === this.transportOriginal.departureTime
						? this.transport.departureTime
						: this.checkDateIfSameDay(this.transport.departureTime),
				arrivalTime:
					this.transport.arrivalTime === this.transportOriginal.arrivalTime
						? this.transport.arrivalTime
						: this.checkDateIfSameDay(this.transport.arrivalTime),
				kgCoTwo: this.greenImpact,
				materialWeight: parseFloat(this.transport.materialWeight),
				carbon: {
					...this.editData.carbon,
					carbonId: +this.editData.carbon.id
				}
			};

			if (this.transport.arrivalTime.split('T')[0] === '0001-01-01') {
				result = _.omit(result, ['arrivalTime']);
			}

			if (this.transport.departureTime.split('T')[0] === '0001-01-01') {
				result = _.omit(result, ['departureTime']);
			}

			if (this.transport.arrivalTime === this.transportOriginal.arrivalTime) {
				result = _.omit(result, ['arrivalTime']);
			}

			if (this.transport.departureTime === this.transportOriginal.departureTime) {
				result = _.omit(result, ['departureTime']);
			}

			if (this.transport.subType === null) {
				result.subType = 0;
			}

			if (this.transport.additionalSubType === null) {
				result.additionalSubType = 0;
			}

			return result;
		},
		checkDateIfSameDay(value) {
			const chooseDate = this.chooseDate;
			const date = moment(new Date(value)).utc().format('YYYY-MM-DD');

			if (chooseDate === date) {
				return new Date(new Date(`${this.chooseDate} ${moment(new Date(value)).utc().format('HH:mm')}`).toUTCString());
			}

			const _d = new Date(value);
			return new Date(new Date(`${moment(_d).utc().format('YYYY-MM-DD')} ${moment(_d).utc().format('HH:mm')}`).toUTCString());
		},
		async submit() {
			const dataToSend = this.prepareDataToSend();

			await updatedTransport(parseInt(this.transport.id, 10), dataToSend);
		},
		resetData() {
			this.userSelected = null;

			this.chooseDate = moment(new Date()).format('YYYY-MM-DD');
			this.chooseTime = '--:--';
			this.departureTime = '00:00';
			this.arrivalTime = '00:00';

			this.transport = {
				type: 0,
				subType: 0,
				additionalSubType: 0
			};

			this.avoidHighways = false;
			this.avoidTolls = false;

			this.vehiculesOptionsList = [];
			this.durationTime = '00:00';

			this.greenImpact = 0;

			this.currVehicleToEdit = null;
			this.showModalVehicle = false;
			this.currSearch = '';

			this.createByOtherUser = false;

			this.isDurationEstimationLoading = false;
			this.durationEstimation = {};

			this.chooseTimeState = {
				state: null,
				message: ''
			};

			this.datePlanifiedByGoogle = false;

			this.transportTypeMapped = 0;

			this.$emit('form-transport:reset-data-success');
		}
	}
};
</script>
